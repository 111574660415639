'use strict';

if (!window.setRecaptchaToken) {
  window.setRecaptchaToken = function (token) {
    document.querySelector('input[name=googleRecaptchaToken]').value = token;
  }
}

if (!window.onRecaptchaExpired) {
  window.onRecaptchaExpired = function () {
    document.querySelector('input[name=googleRecaptchaToken]').value = '';
  }
}

