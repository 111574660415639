'use strict';

function displayMessage(success, msg, form) {
  const span = document.createElement('DIV');
  span.classList.add('alert', 'card-custom', `${success ? 'alert-success' : 'alert-danger'}`);
  span.setAttribute('role', 'alert');
  span.setAttribute('aria-live', 'polite');
  span.innerHTML = msg;
  form.parentNode.appendChild(span);
  $(form).find('button[type=submit]').addClass('disabled')

  setTimeout(function () {
    span.remove();
    $(form).find('button[type=submit]').removeClass('disabled')
  }, 5000);
}

module.exports = function () {
  $('.newsletter-form').on('bouncerFormValid', function (e) {
    let form = e.target;
      e.preventDefault();

      const email_input = $(form).find('input[name=email]');
      const newsletter = {
        newsletter: {
          email: $(email_input).val(),
          privacy: 1,
        },
      };
      const url = 'https://coinconnect.coin.it/newsletters.json';
      $.ajax({
        type: 'POST',
        url: $(form).data('optionUrl'),
        data: newsletter,
        success: function (jsondata) {
          console.log('success :' + jsondata);
        },
      })
        .done(function (data) {
          console.log(data);
          displayMessage(true, $(form).data('optionSuccess'), form);
        })
        .fail(function (response) {
          console.log(response);
          var errorMsg = $(form).data('optionError')
          if (response.status == 422) {
            if (response.responseJSON && response.responseJSON.errors && response.responseJSON.errors.email_registered && response.responseJSON.errors.email_registered.length) {
              errorMsg = response.responseJSON.errors.email_registered[0]
            } else {
              errorMsg = $(form).data('optionErrorEmailRegistered')
            }
          }
          displayMessage(false, errorMsg, form);
        });
  });
};
