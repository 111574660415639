'use strict';

module.exports = function (include) {
  if (typeof include === 'function') {
    include();
  } else if (typeof include === 'object') {
    Object.keys(include).forEach(function (key) {
      if (typeof include[key] === 'function') {
        include[key]();
      }
    });
  }
};
