'use strict';
/**
 * Renders a modal window that will track the users consenting to accepting site tracking policy
 */
function showConsentModal() {
  $('#consent-modal').attr('aria-hidden', false);
  $('#consent-modal').addClass('is-open');

  $('body').on('click', '.consent-button', function (e) {
    e.preventDefault();
    let url = $(this).data('url');
    $.ajax({
      url: url,
      type: 'get',
      dataType: 'json',
      success: function (data) {
        $('#consent-modal').removeClass('is-open');
        $('#consent-modal').attr('aria-hidden', true);
        document.cookie = 'cookie_consent=' + data.cookieConsent;

        $.spinner().stop();
      },
      error: function () {
        $('#consent-modal').removeClass('is-open');
        $('#consent-modal').attr('aria-hidden', true);

        $.spinner().stop();
      },
    });
  });
}

module.exports = function () {
  if ($('.tracking-consent').hasClass('api-true') && !$('.tracking-consent').hasClass('consented')) {
    showConsentModal();
  }
};
